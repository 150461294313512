import styled from 'styled-components';

export const PagginationContainer = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
  margin: auto;
  width: 15vw;
  justify-content: space-around;
`;

export const PageList = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
`;

export const PageEl = styled.li`
  margin-inline: 10px;
  text-decoration: ${(props) => (props.current ? 'underline' : 'none')};
  cursor: pointer;
`;
