import { useState } from 'react';
import Paggination from './components/paggination/Index';

import ProductList from './components/productList/Index';
import Searchbar from './components/searchbar/Index';
import SelectProductPerPage from './components/selectProductsPerPage/Index';
import { Options, Title, Wrapper } from './style';

const App = () => {
  const [prodPerPage, setProdPerPage] = useState(9);
  const [totalPages, setTotalPages] = useState(0);

  const [id, setId] = useState('');
  const [page, setPage] = useState(0);

  return (
    <div className='App'>
      <Title>Find your colors</Title>

      <Wrapper>
        <Options>
          <SelectProductPerPage
            onSelectChange={(value) => setProdPerPage(value)}
          />
          <Searchbar onIdChange={(value) => setId(value)} />
        </Options>
        <ProductList
          setTotalPages={(value) => setTotalPages(value)}
          setPage={(value) => setPage(value)}
          prodPerPage={prodPerPage}
          currentPage={page}
          id={id}
        />
      </Wrapper>

      <Paggination
        totalPages={totalPages}
        page={page}
        changePage={(page) => setPage(page)}
      />
    </div>
  );
};

export default App;
