import { createGlobalStyle } from 'styled-components';
import backgroundImage from './assets/background.png';

export default createGlobalStyle`
  @font-face {
    font-family: 'Playfair';
    src: url(fonts/PlayfairDisplay-Regular.otf) format('opentype');
  }

  @font-face {
    font-family: 'PlayfairBold';
    src: url(fonts/PlayfairDisplay-Bold.otf) format('opentype');
  }
  

  body {
    margin: 0;
    padding: 0;
    width: 100vw;
    color: ${(props) => (props.darkMode ? 'white' : 'black')};
    background-image: url(${backgroundImage});
    background-attachment: fixed;
  }

  * {
    color: #080D0E;
    font-family: 'Playfair', serif;
    font-weight: 400;
    box-sizing: border-box;
    font-size: 25px;
  }
`;
