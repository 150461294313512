import { ProductContainer, ProductName, ProductYear } from './style';

const Product = ({ name, year, color, pantone_value }) => {
  return (
    <ProductContainer color={color}>
      <div className='info'>
        <ProductName>{name}</ProductName>
        <ProductYear>{year}</ProductYear>
      </div>
    </ProductContainer>
  );
};

export default Product;
