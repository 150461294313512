import Product from '../product/Index';
import { List } from './style';
import { useState, useEffect } from 'react';

const ProductList = ({
  prodPerPage,
  id,
  currentPage,
  setPage,
  setTotalPages,
}) => {
  const [productList, setProductList] = useState([]);

  const getProduct = async () => {
    const res = await fetch(
      `https://reqres.in/api/products?page=${currentPage}&per_page=${prodPerPage}${
        id !== '' ? '&id=' + id : ''
      }`
    );
    const json = await res.json();

    if (id === '') {
      setProductList(json.data);
    } else if (Object.keys(json).length !== 0) {
      setProductList([json.data]);
    } else {
      setProductList([]);
    }

    setTotalPages(json.total_pages);
    setPage(json.page);
  };

  useEffect(() => {
    getProduct();
  }, [prodPerPage, id, currentPage]);

  return (
    <List>
      {productList.map((product) => (
        <Product
          name={product.name}
          year={product.year}
          color={product.color}
          key={product.id}
        />
      ))}
    </List>
  );
};

export default ProductList;
