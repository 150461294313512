import { useState, useEffect } from 'react';

import { PagginationContainer, PageList, PageEl } from './style';

const Paggination = ({ totalPages, page, changePage }) => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    for (let i = 1; i <= totalPages; i++) {
      setPages((prev) => [
        ...prev,
        <PageEl key={i} current={page === i} onClick={() => changePage(i)}>
          {i}
        </PageEl>,
      ]);
    }

    return () => setPages([]);
  }, [totalPages, page]);

  return (
    <PagginationContainer>
      <h1>Pages:</h1>
      <PageList>{pages}</PageList>
    </PagginationContainer>
  );
};

export default Paggination;
