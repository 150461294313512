import styled from 'styled-components';

export const ProductContainer = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  background-color: ${(props) => props.color};
  box-shadow: 0px 4px 32px ${(props) => props.color};
  width: 380px;
  height: 250px;
  padding: 20px 30px;
  margin-bottom: 40px;
  border-radius: 28px;

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }
`;

export const ProductName = styled.h1`
  font-family: 'PlayfairBold';
  margin: 0;
`;

export const ProductYear = styled.p`
  margin: 0;
`;
