import { TextInput } from './style';

const Searchbar = ({ onIdChange }) => {
  return (
    <TextInput
      placeholder='Searchg by id'
      type='number'
      onChange={(e) => onIdChange(e.target.value)}
    />
  );
};

export default Searchbar;
